.testimonial {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
}

.wrapper {
    display : grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
}

.wrapper>img {
    width: 20rem;
    justify-self: center;
    border-radius: 50%;
    background-color: wheat;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
}

.container>:nth-child(2){
    font-size: 0.8rem;
    text-transform: uppercase;
    display: block;
    
}

.wrapper>:nth-child(3) {
   text-align: right;
}


.reviews{
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.carousel{
    width:100%
}
.tCarousel{
    padding: 2rem;
}

.testimonial{
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border-radius: .5rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0 1rem 3rem -50px #7d7d7d;
}

.testimonial> img {
    position: absolute !important;
    width: 3rem !important;
    top: -2rem !important;
    left: 45%;
    border-radius: 50%;
    background: white;
};

.testimonial>span:nth-of-type(1){
    align-items: center;
    font-weight: 0.8rem;
    letter-spacing: 1px;
    margin-top: 2rem;
}

.testimonial>hr{
    background:rgb(198, 198, 198);
    width:80%;
    height: 1px;
    border: none;
}

.testimonial>span:nth-of-type(2){
    font-weight: 500;
}

@media screen  and (max-width: 856px) {
    .testimonial {
        gap: 2rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top:2rem;
        margin-bottom: 2rem;
        padding: 1rem;
        text-align: center;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .wrapper>img {
        width: 15rem;
        justify-self: center;
    }
    .container{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .container>:nth-child(1){
        font-size: 1.5rem;
        text-transform: uppercase;
        display: block;
        font-weight: bold;
    }
    .container>:nth-child(2){
        font-size: 0.8rem;
        text-transform: uppercase;
        display: block;
        
    }
    .wrapper>:nth-child(3) {
    text-align: right;
    }
    .testimonial> img {
        position: absolute !important;
        width: 3rem !important;
        top: -1.5rem !important;
        left: 45%
    };
    .testimonial>span:nth-of-type(1){
        align-items: center;
        font-weight: 0.8rem;
        letter-spacing: 1px;
        margin-top: 2rem;
    }
    .testimonial>hr{
        background:rgb(198, 198, 198);
        width:80%;
        height: 1px;
        border: none;
    }
    .testimonial>span:nth-of-type(2){
        font-weight: 500;
    }
}
    
@media screen  and (max-width: 640px){
    .wrapper{
        grid-template-columns: 1fr ;
    }

    .wrapper>img {
        width: 80vw;
    }

    .wrapper > :nth-child(3),
    .wrapper > :nth-child(1){
        text-align: center;
    }
}