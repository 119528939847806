.s-container {
    position: relative;

}

.s-container .swiper {
    width: 70%;
    height: 10rem;
    position: unset;
    margin-top: 170px;
}

.s-container .swiper .swiper-slide {
    background: white;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev {
  left: 6rem;
}

.s-container .swiper-button-next {
    right: 6rem;
  }

.s-container .swiper-button-prev, .s-container .swiper-button-next {
    color: black;
}

.s-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name {
    display: flex;
    flex-direction: column;
}

.name> :nth-child(1) {
    font-size: 0.7rem;
    
}

.left-s> :nth-child(2) {
   font-size: 2rem;
   font-weight: bold;
}

.left-s> :nth-child(3) {
    font-size: 0.6rem;
    border: 1px solid var(--black);
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}

.img-p{
    transform: rotate(-20deg);
    position: absolute;
    right: 0;
    width: 40%;
    height: 100%;
    bottom: -20%;
}

@media screen and (max-width: 856px) {
 s.container .swiper{
        width: .90%;
        height: 10rem;
    } 

 s.container .swiper-button-prev, 
    s.container .swiper-button-next {
        display: none;
    }
    
 

}

